<template>
  <div id="Container">
    <DxScrollView direction="vertical">
     <Transaction :ref="transactionRefName" 
                  @setLotInfoExt="setLotInfoExt"
                  @clearLotInfoExt="clearLotInfoExt"
                  @execute="execute"/>
    
     <DxDataGrid
        :ref="gridRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        :key-expr="item"
        :selection="{ mode: 'single' }"
        @cellDblClick="onDblClick">

        <DxSorting mode="none"/>

        <DxColumn
          data-field="item"
          caption="검사 항목">
        </DxColumn>

        <DxColumn
          data-field="result"
          caption="결과1"
          alignment="center">  
        </DxColumn>

        <DxColumn
          data-field="result2"
          caption="결과2"
          alignment="center">  
        </DxColumn>
         
        <DxColumn
          data-field="result3"
          caption="결과3"
          alignment="center">  
        </DxColumn>
 
        <DxColumn
          data-field="average"
          caption="평균"
          alignment="center">  
        </DxColumn>
 
        <DxColumn
          data-field="unit"
          caption="단위"
          alignment="center">  
        </DxColumn>
        
        <DxColumn
          data-field="lsl"
          caption="LSL"
          alignment="center">  
        </DxColumn>

        <DxColumn
          data-field="tg"
          caption="기준 값"
          alignment="center">  
        </DxColumn>

        <DxColumn
          data-field="usl"
          caption="USL"
          alignment="center">  
        </DxColumn>

        <DxColumn
          data-field="tolerance"
          caption="허용 오차"
          alignment="center">  
        </DxColumn>

        <DxColumn
          data-field="judgement"
          caption="판정"
          alignment="center">  
        </DxColumn>        

      </DxDataGrid>
      
       <DxPopup :ref="popupNumberRefName"
                :drag-enabled="false"
                :close-on-outside-click="true"
                :show-title="true"
                :width="300"
                :height="200"
                title="결과 입력">
                <div class='Item'>항목: {{currentRow.item}}</div>
                <DxNumberBox :value="numberValue"
                             @valueChanged="setNumberValue"/> 
                <div class='ButtonBox'>
                  <DxButton class='Button'
                      :width="120"
                      text="입력"
                      type="success"
                      styling-mode="contained"
                      @click="setResult('')"/>
                </div>
       </DxPopup>

       <DxPopup :ref="popupPassFailRefName"
                :drag-enabled="false"
                :close-on-outside-click="true"
                :show-title="true"
                :width="300"
                :height="230"
                title="결과 입력">
                <div class='Item'>항목 : {{currentRow.item}}</div>
                <div class='ButtonBox'>
                  <DxButton class='Button'
                    :width="120"
                    text="Pass"
                    type="success"
                    styling-mode="contained"
                    @click="setResult('PASS')"/>
                  <DxButton class='Button'
                    :width="120"
                    text="Fail"
                    type="danger"
                    styling-mode="contained"
                    @click="setResult('FAIL')"/>
                </div>
       </DxPopup>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import {DxDataGrid,
          DxColumn,
          DxSorting} from "devextreme-vue/data-grid";
  import DxPopup from "devextreme-vue/popup";
  import {DxNumberBox} from "devextreme-vue/number-box";
  import DxButton from "devextreme-vue/button";
  import Transaction from "../_common/TRANSACTION";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxScrollView,
      DxDataGrid,
      DxColumn,
      DxSorting,
      DxPopup,
      DxNumberBox,
      DxButton,
      Transaction
    },
    data: function() {
      return {
        transactionRefName: 'transaction',
        gridRefName: 'grid',
        popupNumberRefName: 'popupNumber',
        popupPassFailRefName: 'popupPassFail',
        currentRow:{},
        numberValue: '',
        targetResultField: ''
      };
    },
    methods: {
      setLotInfoExt(lotInfo){
        dsu.getAxiosOBJGet('/api/mes/MISP/select', lotInfo).then(res =>{
          let data = res.data.data;
          if(res.data.result == 'SUCCESS'){
              if(data.length == 0){
                fn.notifyError('검사 기준 미 설정');
                this.$refs[this.transactionRefName].clear();
                return;
              }else{
                this.setGridData(data);
              }
          }else{
            fn.notifyError(res.data.msg);
          }
        });

      },
      clearLotInfoExt(){
        this.setGridData([]);
      },
      onDblClick(e) {
        this.targetResultField = e.column.dataField;
        if (e.rowType == 'header') { 
          if (this.targetResultField == 'judgement'){
              var inspectionResultList = this.getGridData();
              for (var i = 0; i < inspectionResultList.length; i++) {
                if (inspectionResultList[i].method == 'PASS/FAIL') {
                  inspectionResultList[i].result = 'PASS';
                  inspectionResultList[i].result2 = 'PASS';
                  inspectionResultList[i].result3 = 'PASS';
                } else {
                  var decimalPlaces= fn.getDecimalPlaces(inspectionResultList[i].lsl);
                  
                  inspectionResultList[i].result = fn.getRandomInRange(inspectionResultList[i].lsl, inspectionResultList[i].tg, inspectionResultList[i].usl, decimalPlaces);
                  inspectionResultList[i].result2 = fn.getRandomInRange(inspectionResultList[i].lsl, inspectionResultList[i].tg, inspectionResultList[i].usl, decimalPlaces);
                  inspectionResultList[i].result3 = fn.getRandomInRange(inspectionResultList[i].lsl, inspectionResultList[i].tg, inspectionResultList[i].usl, decimalPlaces);
                  inspectionResultList[i].average = (parseFloat(inspectionResultList[i].result) + parseFloat(inspectionResultList[i].result2) + parseFloat(inspectionResultList[i].result3)) / 3;
                  inspectionResultList[i].average = inspectionResultList[i].average.toFixed(decimalPlaces + 1);
                }
                inspectionResultList[i].judgement = 'PASS';
              }
              this.setGridData(inspectionResultList);
            }
        }
        else {
          this.currentRow = e.row.data;
          if (this.targetResultField.includes('result')) {
            if (!this.currentRow[this.targetResultField]) {
              this.currentRow[this.targetResultField] = '';
            }
            if (this.currentRow.method == 'PASS/FAIL') {
              this.$refs[this.popupPassFailRefName].instance.show();
            } else {
              this.$refs[this.popupNumberRefName].instance.show();
            }
          }
        }
      },
      async execute(transInfo){
        let inspectionResultList = this.getGridData();
       
        for (var i = 0; i < inspectionResultList.length; i++) {
          if(!inspectionResultList[i].result || !inspectionResultList[i].result2 || !inspectionResultList[i].result3){
            fn.notifyError(inspectionResultList[i].item + " 항목 결과 미 입력");
            return;
          }
        }

        transInfo.inspectionResultList = JSON.stringify(inspectionResultList);

        await dsu.getAxiosOBJPost(`/api/mes/MISP/updateInspectionResult`, transInfo).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
            this.$refs[this.transactionRefName].clear();
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      setGridData(data){
        const grid = this.$refs[this.gridRefName].instance;
        grid.option('dataSource', data);
      },
      getGridData(){
        const grid = this.$refs[this.gridRefName].instance;
        var dataSource = grid.getDataSource();
        return dataSource.items();
      },
      setResult(result){
        let finalResult = '';

        this.$refs[this.popupNumberRefName].instance.hide();
        this.$refs[this.popupPassFailRefName].instance.hide();
        
        if(result == ''){
          finalResult = this.numberValue;
          this.numberValue ='';
        }else{
          finalResult = result;
        }
       
        var inspectionResultList = this.getGridData();
        for (var i = 0; i < inspectionResultList.length; i++) {
          if(inspectionResultList[i].item == this.currentRow.item){
            if (inspectionResultList[i].method == 'PASS/FAIL') {
              inspectionResultList[i].result = finalResult;
              inspectionResultList[i].result2 = finalResult;
              inspectionResultList[i].result3 = finalResult;
            } else {
              if (!inspectionResultList[i].result && !inspectionResultList[i].result2 && !inspectionResultList[i].result3) {
                inspectionResultList[i].result = finalResult;
                inspectionResultList[i].result2 = finalResult;
                inspectionResultList[i].result3 = finalResult;
              } else {
                inspectionResultList[i][this.targetResultField] = finalResult;
              }
            }

            if(inspectionResultList[i].result && inspectionResultList[i].result2 && inspectionResultList[i].result3){
              if (inspectionResultList[i].method == 'PASS/FAIL') {
                if(inspectionResultList[i].result == 'PASS' && inspectionResultList[i].result2 == 'PASS' && inspectionResultList[i].result3 == 'PASS'){
                  inspectionResultList[i].judgement = 'PASS';
                } else {
                  inspectionResultList[i].judgement = 'FAIL';
                } 
              } else {
                if (inspectionResultList[i].lsl <= inspectionResultList[i].result && inspectionResultList[i].usl >= inspectionResultList[i].result
                    && inspectionResultList[i].lsl <= inspectionResultList[i].result2 && inspectionResultList[i].usl >= inspectionResultList[i].result2
                    && inspectionResultList[i].lsl <= inspectionResultList[i].result3 && inspectionResultList[i].usl >= inspectionResultList[i].result3
                ){
                  inspectionResultList[i].judgement = 'PASS';
                }else{
                  inspectionResultList[i].judgement = 'FAIL';
                }
                let decimalPlaces = fn.getDecimalPlaces(inspectionResultList[i].lsl); 

                inspectionResultList[i].average = (parseFloat(inspectionResultList[i].result) + parseFloat(inspectionResultList[i].result2) + parseFloat(inspectionResultList[i].result3)) / 3;
                inspectionResultList[i].average = inspectionResultList[i].average.toFixed(decimalPlaces + 1);
              }
            } else {
              inspectionResultList[i].judgement = '';
              inspectionResultList[i].average = '';
            }
          }
        }
        
        this.setGridData(inspectionResultList);
      },
      setNumberValue(e){
        this.numberValue = e.value;
      }
    
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }

  .Item{
    margin-bottom: 15px;
  }

  .ButtonBox{
    text-align: center;
  }

  .Button{
    margin: 7px;
  }
  
</style>