<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <Transaction :ref="transactionRefName" 
                   @setLotInfoExt="setLotInfoExt"
                   @clearLotInfoExt="clearLotInfoExt"
                   @execute="execute"/>
    
      <DxForm>
        <DxGroupItem caption="선택 정보">
          <DxSimpleItem name="nextOperation">
            <DxLabel text="다음 공정" />
            <template #default>
              <DxSelectBox
                placeholder=""
                value-expr="operation"
                display-expr="codeName"
                :value="formData.nextOperation"
                :data-source="operationList"
                @value-changed="changedNextOperation"/>
            </template>
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import Transaction from "../_common/TRANSACTION";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxScrollView,
      Transaction,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxSelectBox
    },
    data: function() {
      return {
        transactionRefName: 'transaction',
        formData: {nextOperation: ''},
        operationList: []
      };
    },
    mounted(){
      dsu.getAxiosOperationList({eqpOper : 'N'}).then(res => {this.operationList = res.data.data});
    },
    methods: {
      // setLotInfoExt(lotInfo){

      // },
      clearLotInfoExt(){
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
      },
      changedNextOperation(e){
        this.formData.nextOperation = e.value;
      },
      async execute(transInfo) {
        transInfo.nextOperation = this.formData.nextOperation;
        
        if(!transInfo.nextOperation){
          fn.notifyError("다음 공정 선택 필요");
          return;
        }

        if(transInfo.nextOperation == '9000'){
          fn.notifyError("건설 현장으로 이동은 출고 기능 사용필요");
          return;
        }

        if(transInfo.operation == transInfo.nextOperation){
          fn.notifyError("같은 공정으로 이동 불가");
          return;
        }

        if(transInfo.batchId){
          fn.notifyError("Batch 구성된 Lot은 이동 불가");
          return;
        } 
        
        await dsu.getAxiosOBJPost(`/api/mes/MPOU/moveOper`,transInfo).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
            this.$refs[this.transactionRefName].clear();
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
</style>