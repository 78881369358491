<template>
  <div>
    <DxToolbar>
      <DxItem
        widget="dxButton"
        :options="btnOptBarcode"
        location="before"/>
      <DxItem
        v-if="!hideExecute"
        widget="dxButton"
        :options="btnOptExecute"
        location="after"/>
    </DxToolbar>
    
    <div>
      <qrcode-stream 
          @decode="onDecode"
          @init="onInit"
          v-if="!destroyed">
          <div class="loading-indicator"
              v-if="loading">
              바코드 스캐너 로딩 중....
          </div>
      </qrcode-stream>
    </div>
    <DxForm :ref="formRefNameSearch"
            :form-data="formDataSearch">  
      <DxSimpleItem 
        data-field="searchLot"
        editor-type="dxTextBox"
        :editor-options="{inputAttr: {'style': 'text-transform: uppercase'}, onKeyUp:keyUp}">
        <DxLabel text="Lot 조회"/>
      </DxSimpleItem>
    </DxForm>
 
    <DxForm :ref="formRefName"
            :form-data="formData">  
      <DxGroupItem caption="Lot 정보">
        <DxSimpleItem name="lotNumber">
          <DxLabel text="Lot No."/>
          <template #default>
            <DxTextBox
              :read-only="true"
              :value="formData.lotNumber"/>
          </template>
        </DxSimpleItem>

        <DxSimpleItem name="code">
          <DxLabel text="자재" />
          <template #default>
            <DxSelectBox
              placeholder=""
              value-expr="code"
              display-expr="codeName"
              readOnly="true"  
              :value="formData.code"
              :data-source="materialList"/>
          </template>
        </DxSimpleItem>              

        <DxSimpleItem name="quantity">
          <DxLabel text="수량"/>
          <template #default>
            <DxTextBox
              :read-only="true"
              :value="formData.quantity + ' ' + formData.unit"/>
          </template>
        </DxSimpleItem>

        <DxSimpleItem v-if="!hideOperation" name="operation">
          <DxLabel text="현재 공정" />
          <template #default>
            <DxSelectBox
              placeholder=""
              value-expr="operation"
              display-expr="codeName"
              readOnly="true"  
              :value="formData.operation"
              :data-source="operationList"/>
          </template>
          </DxSimpleItem>
      </DxGroupItem>
      <DxGroupItem caption="진행 시간 변경">
        <DxSimpleItem data-field="mTransTime" editor-type="dxDateBox" :editorOptions="{ type:'datetime', displayFormat:'yyyy-MM-dd HH:mm', showClearButton: true}">
          <DxLabel text="진행 시간"/>
        </DxSimpleItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import {DxTextBox } from "devextreme-vue/text-box";
  import {QrcodeStream} from "vue-qrcode-reader";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxToolbar,
      DxItem,
      DxSelectBox,
      DxTextBox,
      QrcodeStream},
    data: function() {
      return {
        formRefName: 'form',
        formRefNameSearch: 'formSearch',
        loading: false,
        destroyed: true,
        formData: {quantity: '', unit: ''},
        formDataSearch: {searchLot: ''},
        materialList: [],
        operationList: [],      
        btnOptBarcode: {
            type: 'custom',
            text: '바코드',
            stylingMode:"outlined",
            onClick: this.toggle
        },
        btnOptExecute: {
            type: 'custom',
            text: '실행',
            stylingMode:"outlined",
            onClick: this.execute
        }
      };
    },
    mounted(){
      dsu.getAxiosMaterialList().then(res => {this.materialList = res.data.data});
      dsu.getAxiosOperationList({eqpOper : 'N'}).then(res => {this.operationList = res.data.data});
    },
    methods: {
      clear(){
        this.$refs[this.formRefNameSearch].instance.resetValues();
        this.$refs[this.formRefName].instance.resetValues();
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
        this.$emit('clearLotInfoExt');    
      },
      execute(){
        if (!this.formData.lotNumber){
            fn.notifyError('Lot 조회 후 진행 필요');
            return;
        }
        var params = JSON.parse(JSON.stringify(this.formData));
        
        if(this.formData.mTransTime instanceof Date){
          params.mTransTime = dsu.getConvTransTime(this.formData.mTransTime);
        } else {
          params.mTransTime = '';
        }
        
        this.$emit('execute', params);

      },
      async onInit(promise) {
        this.loading = true
        try {
          await promise
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      },
      onDecode(result) {
        this.formDataSearch.searchLot = result;
        this.getLotInfo();
        this.toggle();
      },
      async toggle(){
        this.destroyed = !this.destroyed;
        await this.$nextTick();
      },
      keyUp(e)
      {
        if (e.event.key == 'Enter'){
          this.getLotInfo();
        }
      },
      getLotInfo(){
        var params = {};

        if(!this.formDataSearch.searchLot){
            fn.notifyError('Lot No. 입력 후 검색필요');
            return;
        }

        params.lotNumber = this.formDataSearch.searchLot.toUpperCase();
        this.formDataSearch.searchLot = ''

        if(this.formData.lotNumber == params.lotNumber){
          fn.notifyError('같은 Lot 병합 불가');
          return;
        }
        
        dsu.getAxiosOBJGet('/api/mes/MVLM/select', params).then(res => {
          let response = res.data;
          if (response.result == 'SUCCESS'){
            if(response.data.length == 0){
              fn.notifyError('존재하지 않는 Lot');
              return;
            }else{
              if(response.data[0].batchId){
                fn.notifyError('Batch로 구성된 Lot은 Merge 불가');
                return;
              } 

              if(!this.formData.lotNumber){
                this.formData = response.data[0];
              }else{
                if(this.formData.operation != response.data[0].operation){
                  fn.notifyError('다른 공정에서 병합 불가');
                  return;
                }

                if(this.formData.code != response.data[0].code){
                  fn.notifyError('다른 자재 병합 불가');
                  return;
                }
            
                this.$emit('setLotInfoExt', response.data[0]);
              }
            }
          }else{
            fn.notifyError(response.msg);
            this.clear();
          }
        });
      

      }
    }
  };
</script>

<style scoped>
  .loading-indicator {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
  }

  .dx-field-label{
    width: 75px;
    font-size: 14px;
    font-weight: 700;
    color: black;
  }
</style>