<template>
  <div>
    <DxToolbar>
      <DxItem
        widget="dxButton"
        :options="btnOptBarcode"
        location="before"/>
      <DxItem
        widget="dxButton"
        :options="btnOptExecute"
        location="after"/>
    </DxToolbar>
    
    <div>
      <qrcode-stream 
          @decode="onDecode"
          @init="onInit"
          v-if="!destroyed">
          <div class="loading-indicator"
              v-if="loading">
              바코드 스캐너 로딩 중....
          </div>
      </qrcode-stream>
    </div>
    
    <DxForm :ref="formRefName"
            :form-data="formData">
      <DxSimpleItem 
        data-field="search"
        editor-type="dxTextBox"
        :editor-options="{inputAttr: {'style': 'text-transform: uppercase'}, onKeyUp:keyUp}">
        <DxLabel text="조회"/>
      </DxSimpleItem>

      <DxSimpleItem data-field="mTransTime" editor-type="dxDateBox" :editorOptions="{ type:'datetime', displayFormat:'yyyy-MM-dd HH:mm', showClearButton: true}">
        <DxLabel text="진행 시간"/>
      </DxSimpleItem>    
    </DxForm>
  </div>
</template>
<script>
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import {DxForm,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {QrcodeStream} from "vue-qrcode-reader";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxForm,
      DxSimpleItem,
      DxLabel,
      DxToolbar,
      DxItem,
      QrcodeStream},
    data: function() {
      return {
        formRefName: 'form',
        loading: false,
        destroyed: true,
        formData: {search: ''},
        btnOptBarcode: {
            type: 'custom',
            text: '바코드',
            stylingMode:"outlined",
            onClick: this.toggle
        },
        btnOptExecute: {
            type: 'custom',
            text: '실행',
            stylingMode:"outlined",
            onClick: this.execute
        }
      };
    },
    methods: {
      clear(){
          this.$refs[this.formRefName].instance.resetValues();
          Object.keys(this.formData).map((key) => {
              this.formData[key] ='';
          });
          this.$emit('clearLotList');    
      },
      onDecode(result) {
        this.formData.search = result;
        this.getLotList();
        this.toggle();
      },
      async toggle(){
        this.destroyed = !this.destroyed;
        await this.$nextTick();
      },
      keyUp(e)
      {
        if (e.event.key == 'Enter'){
          this.getLotList();
        }
      },
      getLotList(lotBatchId) {
        var params = { sortString: 'CODE' };

        if (lotBatchId) {
          this.formData.search = lotBatchId;
        }

        if(!this.formData.search){
            fn.notifyError('검색 값 입력 후 검색필요');
            return;
        }
        
        let search = this.formData.search.toUpperCase();
        let keyName = '';

        this.formData.search = '';

        if(search.substring(0,1) == 'B'){
          params.batchId = search;
          keyName = 'Batch';

        }else{
          params.lotNumber = search;
          keyName = 'Lot';
        }
        dsu.getAxiosOBJGet('/api/mes/MVLM/select', params).then(res => {
          let data = res.data.data;
          
          if(res.data.result == 'SUCCESS'){
            if(data.length == 0){
              fn.notifyError('존재하지 않거나 출고된 ' + keyName);
              this.clear();
              return;
            }else{
              if(keyName =='Lot'){
                if(data[0].batchId){
                  fn.notifyError('Batch로 구성된 Lot은 Batch 단위로 출하 필요');
                  this.clear();
                  return;
                }
              }else{
                if(data[0].batchStatus != '00'){
                  fn.notifyError('이미 출고된 Batch');
                  this.clear();
                  return;
                }
              }

              if (data[0].ship != 'Y') {
                if (lotBatchId) { 
                  fn.notifyError('출고 가능 공정 아님, ' + lotBatchId);
                } else {
                  fn.notifyError('출고 가능 공정 아님');
                }
                this.clear();
                return;
              }
        
              this.$emit('setLotList', data);
            }
          }else{
            fn.notifyError(res.data.msg);
          }
        }); 
      },
      execute() {
        var params = JSON.parse(JSON.stringify(this.formData));
        
        if(this.formData.mTransTime instanceof Date){
          params.mTransTime = dsu.getConvTransTime(this.formData.mTransTime);
        } else {
          params.mTransTime = '';
        }
        
        this.$emit('execute', params);
      }
    }
  };
</script>

<style scoped>
  .loading-indicator {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
  }
</style>